import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import ProfileInfo from 'components/ProfileInfo/ProfileInfo';
import TextField from 'components/TextField/TextField';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import GooglePlaceField from '../ViewOrEditPractice/GooglePlaceField/GooglePlaceField';
import { isValidEmail } from 'utils/validations';
import PhoneField from '../ViewOrEditPractice/PhoneField/PhoneField';
import {
  loadAuthToken,
  loadRole,
  loadSelectedClinicId,
  saveRole,
  saveSelectedClinicId,
} from 'utils/storage';
import axiosInstance from 'apis/axiosInstance';
import Toast, { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import toast from 'react-hot-toast';
import extractGoogleClinicSchedule from 'utils/extractGoogleClinicSchedule';
import { sortBy } from 'lodash';
import { useUser } from 'context/userContext';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/constants/routes';
import { ROLE } from 'utils/constants';
import { IClinicResponse } from 'hooks/useAllClinic';
import { BAD_REQUEST } from 'utils/constants/statusCode';
import { cleanPhoneNumber } from 'utils/formatPhoneNumber';
import { PracticeFormState } from 'interfaces/practiceFormState';
import UnitDurationDropdown from './UnitDurationDropdown/UnitDurationDropdown';
import useModal from 'hooks/useModal';

interface CreatePracticeModalProps {
  onClose: () => void;
  setSelectedPractice: React.Dispatch<
    React.SetStateAction<IClinicResponse | null>
  >;
}

const CreatePracticeModal: FC<CreatePracticeModalProps> = ({
  onClose,
  setSelectedPractice,
}) => {
  const matchMutate = useMatchMutate();
  const { dispatch } = useUser();
  const navigate = useNavigate();
  const {
    control,
    getValues,
    setValue,
    clearErrors,
    watch,
    register,
    formState,
    handleSubmit,
  } = useForm<PracticeFormState>({
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      website: '',
      phoneNumber: '',
      address: '',
      googlePlaceId: '',
      externalId: '',
      subdomain: '',
      isActive: 'true',
      description: '',
      country: '',
      city: '',
      state: '',
      googlePlaceResult: null,
      avatar: '',
      uploadedFile: null,
      zip: '',
      unitDuration: '',
    },
  });

  const { setIsAllowed } = useModal({ onClose });

  const handleUploadImage = async () => {
    const accessToken = loadAuthToken()?.accessToken;
    const uploadedFile = getValues('uploadedFile');
    if (!uploadedFile) return;

    const formData = new FormData();
    formData.append('file', uploadedFile);
    const response = await axiosInstance.post(
      `/upload/avatar/clinics`,
      formData,
      {
        headers: {
          Authorization: `JWT ${accessToken}`,
          'Content-Type': 'multipart/form-data',
          ...(loadRole() === ROLE.PRACTICE_ADMIN
            ? {
                'X-ClinicId': loadSelectedClinicId(),
              }
            : {}),
        },
      }
    );
    return response.data.url;
  };

  const updateProfile = async () => {
    const response = await axiosInstance.get('/clinic-user/auth/profile');
    if (response.data.clinicUserRoles.length === 0) {
      return;
    }

    const sortedClinicUserRoles = sortBy(
      response.data.clinicUserRoles,
      (clinicUserRole) => clinicUserRole.clinic.name
    );

    response.data.clinicUserRoles = sortedClinicUserRoles;

    dispatch({ type: 'getUserProfile', payload: response.data });

    return sortedClinicUserRoles;
  };

  const getTimezone = async () => {
    const { googlePlaceResult } = getValues();
    if (!googlePlaceResult) return;

    const lat = googlePlaceResult.geometry?.location?.lat() ?? 0;
    const lng = googlePlaceResult.geometry?.location?.lng() ?? 0;

    const response = await axiosInstance.get('/gmap/timezone', {
      params: {
        lat,
        lng,
      },
    });

    return response.data.timeZoneId;
  };

  const onSubmit = async () => {
    const accessToken = loadAuthToken()?.accessToken;

    setIsAllowed(false);
    try {
      const [avatar, timezone] = await Promise.all([
        handleUploadImage(),
        getTimezone(),
      ]);

      const {
        phoneNumber,
        address,
        city,
        state,
        country,
        description,
        googlePlaceResult,
        email,
        googlePlaceId,
        externalId,
        subdomain,
        website,
        name,
        zip,
        unitDuration,
      } = getValues();

      const googleClinicSchedule =
        googlePlaceResult && extractGoogleClinicSchedule(googlePlaceResult);

      const response = await axiosInstance.post(
        `/clinics`,
        {
          avatar,
          name,
          phoneNumber: cleanPhoneNumber(phoneNumber),
          address,
          city,
          state,
          country,
          isActive: true,
          timezone,
          description,
          latitude: googlePlaceResult?.geometry?.location?.lat(),
          longitude: googlePlaceResult?.geometry?.location?.lng(),
          email,
          googlePlaceId,
          externalId,
          subdomain,
          pmsType: 'nexhealth',
          website,
          zip,
          unitDuration,
          ...(googleClinicSchedule && { googleClinicSchedule }),
        },
        {
          headers: {
            Authorization: `JWT ${accessToken}`,
          },
        }
      );

      const newlyCreatedClinicId = response.data.id;

      const [mutateResponse] = await Promise.all([
        matchMutate(/\/clinics/),
        updateProfile(),
      ]);

      const updatedClinics = mutateResponse[1].data || mutateResponse[0].data;

      const newlyCreatedClinic = updatedClinics.find(
        (clinic: IClinicResponse) => clinic.id === newlyCreatedClinicId
      );

      if (externalId && subdomain) {
        toast((t) => {
          t.duration = Infinity;
          return (
            <Toast
              id={t.id}
              message="Added a practice successfully"
              type="success"
              isButtonIncluded
              buttonTitle="Open practice view"
              onButtonClicked={() => {
                saveSelectedClinicId(newlyCreatedClinicId);
                saveRole(ROLE.GLOBAL_ADMIN);
                dispatch({
                  type: 'changeClinic',
                  payload: { clinicId: newlyCreatedClinicId },
                });
                navigate(ROUTES.DASHBOARD);
              }}
            />
          );
        });
      }

      setIsAllowed(true);
      setSelectedPractice(newlyCreatedClinic);
      onClose();
    } catch (error: any) {
      if (error.response?.status === BAD_REQUEST) {
        return renderToast({
          message: error.response?.data.message,
          type: 'error',
        });
      }

      renderToast({
        message: 'Something went wrong. Please try again later',
        type: 'error',
      });
    }
  };

  return (
    <DefaultModal>
      <div className="w-[88.2rem] h-[auto] p-2.1 pb-0 text-darkest-grey text-14 normal-case">
        <h3 className="font-bold text-20 leading-[3rem]">
          Create new Practice
        </h3>

        <div className="py-3 h-[60rem] scrollbar overflow-y-auto -mr-1.8 pr-1">
          <div className="flex justify-between">
            <div className="flex flex-col gap-y-1.6">
              <ProfileInfo
                title={'Practice Name'}
                data={getValues('name')}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <TextField
                    id={'name'}
                    control={control}
                    defaultValue={getValues('name')}
                    rules={{
                      required: 'This field is required',
                    }}
                    placeholder="Input Practice Name"
                  />
                </div>
              </ProfileInfo>
              <ProfileInfo
                isEdit
                title={'Google Places ID'}
                data={getValues('googlePlaceId')}
              >
                <div className="min-w-[38rem]">
                  <GooglePlaceField
                    control={control}
                    defaultValue={getValues('googlePlaceId')}
                    setValue={setValue}
                    clearErrors={clearErrors}
                  />
                </div>
              </ProfileInfo>
              <ProfileInfo isEdit title={'Address'} data={getValues('address')}>
                <div className="min-w-[38rem]">
                  <TextField
                    id={'address'}
                    control={control}
                    defaultValue={getValues('address')}
                    rules={{ required: 'This field is required' }}
                    disabled={!!watch('googlePlaceResult')}
                    placeholder="Input Address"
                  />
                </div>
              </ProfileInfo>
              <ProfileInfo isEdit title={'Email'} data={getValues('email')}>
                <div className="min-w-[38rem]">
                  <TextField
                    id={'email'}
                    control={control}
                    defaultValue={getValues('email')}
                    rules={{
                      required: 'This field is required',
                      validate: (value) => {
                        if (!isValidEmail(value))
                          return 'Invalid email address';
                      },
                    }}
                    placeholder="Input Email"
                  />
                </div>
              </ProfileInfo>
              <ProfileInfo
                title={'Phone Number'}
                data={getValues('phoneNumber')}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <PhoneField
                    key={getValues('googlePlaceResult')?.place_id}
                    control={control}
                    phoneNumber={cleanPhoneNumber(watch('phoneNumber'))}
                  />
                </div>
              </ProfileInfo>
              <ProfileInfo
                title={'Website'}
                data={getValues('website')}
                isEdit
                isOptional
              >
                <div className="min-w-[38rem]">
                  <TextField
                    id={'website'}
                    control={control}
                    defaultValue={getValues('website')}
                    placeholder="Input Website"
                  />
                </div>
              </ProfileInfo>
              <ProfileInfo
                isOptional
                title={'Clinic ID'}
                data={getValues('externalId')}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <TextField
                    id={'externalId'}
                    control={control}
                    defaultValue={getValues('externalId')}
                    placeholder="Input Clinic ID"
                  />
                </div>
              </ProfileInfo>
              <ProfileInfo
                isOptional
                title={'Subdomain'}
                data={getValues('subdomain')}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <TextField
                    id="subdomain"
                    control={control}
                    defaultValue={getValues('subdomain')}
                    placeholder="Input Subdomain"
                  />
                </div>
              </ProfileInfo>
              <ProfileInfo
                title={'PMS Unit Duration'}
                data={getValues('unitDuration')}
                isEdit
              >
                <div className="min-w-[38rem]">
                  <UnitDurationDropdown control={control} />
                </div>
              </ProfileInfo>
              <ProfileInfo
                title={'Bio'}
                data={getValues('description')}
                isEdit
                isOptional
              >
                <div className="h-[23.7rem] min-w-[38rem] relative">
                  <textarea
                    className="w-full resize-none h-full text-14 py-1.3 pl-1.6 pr-4.5 rounded-[1rem] border-[0.5px] placeholder:text-mid-grey shadow-primary border-lightest-grey hover:border-magenta hover:shadow-input-active focus:border-magenta focus:shadow-input-active focus-visible:border-magenta focus-visible:shadow-input-active scrollbar !outline-none !ring-transparent"
                    defaultValue={getValues('description')}
                    {...register('description')}
                    placeholder="Input Bio"
                  />
                </div>
              </ProfileInfo>
            </div>
            <div>
              <ImageUpload
                isEdit
                avatar={watch('avatar')}
                onRemoveAvatar={() => {
                  setValue('avatar', null);
                  setValue('uploadedFile', null);
                }}
                setValue={setValue}
              />
            </div>
          </div>
        </div>
        <div className="flex h-[6.6rem] bg-white gap-x-1.6 items-center justify-end">
          <CommonButton onClick={onClose} variant="secondary">
            Cancel
          </CommonButton>
          <CommonButton
            isLoading={formState.isSubmitting}
            disabled={!formState.isDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Create
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default CreatePracticeModal;
